export function ErrorSmallIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='8' cy='8' r='8' fill='#F46857' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.47338 4.47316C4.66376 4.28278 4.97243 4.28278 5.16281 4.47316L11.5268 10.8371C11.7171 11.0275 11.7171 11.3362 11.5268 11.5265C11.3364 11.7169 11.0277 11.7169 10.8373 11.5265L4.47338 5.16259C4.283 4.97221 4.283 4.66354 4.47338 4.47316Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5267 4.47313C11.7171 4.66351 11.7171 4.97218 11.5267 5.16256L5.16276 11.5265C4.97238 11.7169 4.66371 11.7169 4.47333 11.5265C4.28295 11.3361 4.28295 11.0275 4.47333 10.8371L10.8373 4.47313C11.0277 4.28275 11.3363 4.28275 11.5267 4.47313Z'
        fill='white'
      />
    </svg>
  )
}
