import { LoadingButton } from '@mui/lab'
import { Skeleton } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo, useState } from 'react'
import { driverModel } from '~/entities/Driver'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { VerificationFieldElement } from '~/shared/ui/form'
import { RepeatCode } from '~/shared/ui/RepeatCode'
import {
  TIMER_REPEAT_DRIVER_EMAIL,
  TIMER_REPEAT_DRIVER_EMAIL_TIME,
} from '../constants'

import { Description, Title, Wrapper } from './styled'

type CodeConfirmationModalProps = {
  isShow: boolean
  onOk: (code: string) => void
  onCancel: () => void
  onCodeRepeat?: () => void | Promise<void>
  isOkSubmitting: boolean
}

export function DriverEmailConfirmDrawer({
  isShow,
  onOk,
  onCancel,
  onCodeRepeat,
  isOkSubmitting,
}: CodeConfirmationModalProps) {
  const email = useStoreMap(driverModel.$driver, (profile) =>
    profile?.getEmail(),
  )
  const driverPending = useStore(driverModel.getDriverFx.pending)

  const [code, setCode] = useState<string>()

  const handleCodeChange = (code: string) => setCode(code)

  const handleOnOk = () => {
    onOk(code as string)
  }

  const emailExtra = useMemo(
    () =>
      driverPending ? (
        <Skeleton variant='rounded' height={27} width='100%' />
      ) : (
        email
      ),

    [driverPending, email],
  )

  return (
    <DrawerComponent isShow={isShow} onCancel={onCancel}>
      <Wrapper>
        <Title>Подтверждение почты</Title>

        <Description>
          Введите код, который пришел на электронную почту {emailExtra}
        </Description>

        <VerificationFieldElement onChange={handleCodeChange} />
        <br />

        <RepeatCode
          onCodeRepeat={onCodeRepeat}
          time={TIMER_REPEAT_DRIVER_EMAIL_TIME}
          storageKey={TIMER_REPEAT_DRIVER_EMAIL}
        />

        <br />

        <LoadingButton
          onClick={handleOnOk}
          loading={isOkSubmitting}
          fullWidth
          variant='limeFlooded'
          size='large'
          disabled={code?.length !== 4}
        >
          Подтвердить
        </LoadingButton>
      </Wrapper>
    </DrawerComponent>
  )
}
