import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { driverModel } from '~/entities/Driver'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { updateEmailFx } from '../model'
import { Form } from './Form'
import { FormValues } from './Form/model'
import { Title } from './styled'

type PassportDetailsDrawerProps = {
  isShow: boolean
  onClose: () => void
  onSuccess: (formValues: FormValues) => void
}

export function UpdateEmailDrawer({
  isShow,
  onClose,
  onSuccess,
}: PassportDetailsDrawerProps) {
  const isSubmitting = useStore(updateEmailFx.pending)

  const email = useStoreMap(driverModel.$driver, (driver) => driver?.getEmail())

  const defaultValues = useMemo(
    () => ({
      email,
    }),
    [email],
  )

  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Title>{email ? 'Изменить почту' : 'Добавить почту'}</Title>

      <Form
        defaultValues={defaultValues}
        onSuccess={onSuccess}
        isSubmitting={isSubmitting}
      />
    </DrawerComponent>
  )
}
