import { Stack, Typography } from '@mui/material'
import { useStoreMap } from 'effector-react'
import { driverModel } from '~/entities/Driver'
import { ErrorSmallIcon, SuccessSmallIcon } from '~/shared/ui/icons'
import { myPalette } from '~/shared/ui/theme'
import { Button, Field, FieldWrapper, Label } from './styled'

type EmailProps = {
  onChange: () => void
}

export function Email({ onChange }: EmailProps) {
  const email = useStoreMap(driverModel.$driver, (driver) => driver?.getEmail())
  const emailVerifiedAt = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getEmailVerifiedAt(),
  )

  return (
    <>
      <Label>Email</Label>

      <FieldWrapper>
        {email ? (
          <>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Field>{email}</Field>
              {emailVerifiedAt ? <SuccessSmallIcon /> : <ErrorSmallIcon />}
            </Stack>
            <Button onClick={onChange}>Изменить</Button>
          </>
        ) : (
          <Button
            fullWidth
            sx={{
              color: myPalette.midnightBlue.main,
            }}
            variant='lightSteelBlueBorder'
            onClick={onChange}
          >
            Добавить почту
          </Button>
        )}
      </FieldWrapper>

      <Typography color='mutedText.main' fontSize={14} mb='8px'>
        На Email приходят счета, выписки и чеки
      </Typography>
    </>
  )
}
