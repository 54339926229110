import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { interval } from 'patronum'
import { debounce } from 'patronum'
import { isDevEnv } from '~/shared/config/env'

export type NotificationType = {
  id: UniqueId
  title: string
  description: string
  actions?: () => void
}

export const Gate = createGate()

const domain = createDomain('widgets.notifications')

// NotificationsDrawer
export const openNotificationsDrawer = domain.createEvent()
export const closeNotificationsDrawer = domain.createEvent()

export const $isNotificationsDrawerOpen = domain
  .createStore<boolean>(false)
  .on(openNotificationsDrawer, () => true)
  .on(closeNotificationsDrawer, () => false)

// Notifications
export const openNotification = domain.createEvent()
export const closeNotification = domain.createEvent<NotificationType>()

export const $isNotificationOpen = domain
  .createStore<boolean>(false)
  .on(openNotification, () => true)
  .on(closeNotification, () => false)

export const addNotifications = domain.createEvent<NotificationType[]>()
const removeNotification = domain.createEvent<NotificationType>()

export const $notifications = domain
  .createStore<NotificationType[]>([])
  .on(addNotifications, (state, notifications) => [...state, ...notifications])
  .on(removeNotification, (state, notifications) =>
    state.filter(({ id }) => id !== notifications.id),
  )

const debouncedRemoveNotification = debounce({
  source: closeNotification,
  timeout: 400,
})
sample({
  clock: debouncedRemoveNotification,
  target: removeNotification,
})

const debouncedCheckIsOpenNotification = debounce({
  source: $notifications,
  timeout: 400,
})
sample({
  clock: debouncedCheckIsOpenNotification,
  filter: (notification) => Boolean(notification?.length),
  target: openNotification,
})

const requestNotificationsFx = domain.createEffect<void, NotificationType[]>({
  handler() {
    return [
      // {
      //   id: '1',
      //   title: 'Вам необходимо записаться на ТО',
      //   description:
      //     'Ваш километраж превысил 8000 км. Выбрать дату прохождения ТО вы можете во вкладке профиля.',
      //   actions: 'Записаться',
      // },
      // {
      //   id: '2',
      //   title: 'Ваш друг присоединился к реферальной программе',
      //   description:
      //     'Дулмачев Константин Сергеевич присоединился к нам по реферальной программе. По истечению трёх дней вам начислится 1500 ₽.',
      //   actions: 'Реферальная программа',
      // },
    ]
  },
})

sample({
  clock: requestNotificationsFx.doneData,
  target: addNotifications,
})

const intervalRequestStarted = domain.createEvent()
const intervalRequestStopped = domain.createEvent()

if (!isDevEnv) {
  const { tick } = interval({
    timeout: 30000,
    start: intervalRequestStarted,
    stop: intervalRequestStopped,
    leading: true,
    trailing: false,
  })

  sample({
    clock: tick,
    target: requestNotificationsFx,
  })

  sample({
    clock: Gate.open,
    target: intervalRequestStarted,
  })
  sample({
    clock: Gate.close,
    target: intervalRequestStopped,
  })
}
