import { useCallback, useState } from 'react'

type OpenFn = () => void
type CloseFn = () => void

export const useDrawer = (): [boolean, OpenFn, CloseFn] => {
  const [isDrawerShow, toggleIsDrawerShow] = useState(false)

  const openDrawer = useCallback(() => {
    toggleIsDrawerShow(true)
  }, [])

  const closeDrawer = useCallback(() => {
    toggleIsDrawerShow(false)
  }, [])

  return [isDrawerShow, openDrawer, closeDrawer]
}
