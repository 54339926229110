import { styled, Typography, Button as MuiButton, Box } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Label = styled(Typography)`
  font-size: 12px;
  color: ${myPalette.mutedText.main};
  margin-bottom: 4px;
`

export const Field = styled(Typography)`
  font-size: 16px;
  color: ${myPalette.midnightBlue.main};
  line-height: 24px;
`

export const Button = styled(MuiButton)`
  color: ${myPalette.blue.main};
  font-size: 16px;
  font-weight: 400;
  padding: 4px;
` as typeof MuiButton

export const FieldWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`
