import { createDomain } from 'effector'

const domain = createDomain('entities.profile')

export const openProfileDrawer = domain.createEvent()
export const closeProfileDrawer = domain.createEvent()

export const $isProfileDrawerOpen = domain
  .createStore<boolean>(false)
  .on(openProfileDrawer, () => true)
  .on(closeProfileDrawer, () => false)

export const openDriverEmailDrawer = domain.createEvent()
export const closeDriverEmailDrawer = domain.createEvent()

export const $isDriverEmailDrawerOpen = domain
  .createStore<boolean>(false)
  .on(openDriverEmailDrawer, () => true)
  .on(closeDriverEmailDrawer, () => false)
