import { useStore } from 'effector-react'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { useDrawer } from '~/shared/ui/Drawer'
import { useStorageRepeatCode } from '~/shared/ui/RepeatCode'
import { ConfirmationRequired } from './ConfirmationRequired'
import {
  TIMER_REPEAT_DRIVER_EMAIL,
  TIMER_REPEAT_DRIVER_EMAIL_TIME,
} from './constants'
import { DriverEmailConfirmDrawer } from './DriverEmailConfirmDrawer'
import { Email } from './Email'
import {
  resendVerificationEmailCodeFx,
  updateEmail,
  verifyEmail,
  verifyEmailFx,
} from './model'
import { UpdateEmailDrawer, updateEmailModel } from './UpdateEmailDrawer'

export function DriverEmail() {
  const isVerifyEmailFxPending = useStore(verifyEmailFx.pending)
  const [isUpdateEmailShow, updateEmailOpen, updateEmailClose] = useDrawer()
  const [
    isDriverEmailConfirmShow,
    driverEmailConfirmOpen,
    driverEmailConfirmClose,
  ] = useDrawer()

  const { runTimer, getIsTimerWorking } = useStorageRepeatCode(
    TIMER_REPEAT_DRIVER_EMAIL,
  )

  const warningRequestCod = () => {
    snackbarEnqueued({
      message:
        'Запросите код повторно после того как пройдет блокировка получения кода',
      variant: 'warning',
    })
  }

  const handleTimerRun = () => runTimer(TIMER_REPEAT_DRIVER_EMAIL_TIME)

  const handleVerificationEmailCodeResend = async () => {
    if (getIsTimerWorking()) {
      warningRequestCod()
      driverEmailConfirmOpen()
    } else {
      await resendVerificationEmailCodeFx({
        successFn: () => {
          handleTimerRun()
          driverEmailConfirmOpen()
        },
      })
    }
  }

  const handleEmailUpdate = (formValues: updateEmailModel.FormValues) => {
    updateEmail({
      ...formValues,
      successFn: () => {
        updateEmailClose()
        handleTimerRun()
        driverEmailConfirmOpen()
      },
    })
  }

  const handleCodeSend = (code: string) => {
    verifyEmail({
      code,
      successFn: driverEmailConfirmClose,
    })
  }

  return (
    <>
      <Email onChange={updateEmailOpen} />

      <ConfirmationRequired onCodeRepeat={handleVerificationEmailCodeResend} />

      <UpdateEmailDrawer
        isShow={isUpdateEmailShow}
        onClose={updateEmailClose}
        onSuccess={handleEmailUpdate}
      />

      <DriverEmailConfirmDrawer
        isShow={isDriverEmailConfirmShow}
        onCancel={driverEmailConfirmClose}
        onCodeRepeat={handleVerificationEmailCodeResend}
        onOk={handleCodeSend}
        isOkSubmitting={isVerifyEmailFxPending}
      />
    </>
  )
}
