import { useStore } from 'effector-react'
import { Fragment, ReactNode } from 'react'
import { BigInfoToast } from './BigInfoToast'
import { DefaultToast } from './DefaultToast'
import { $notifications } from './model'

type Props = { children: ReactNode }

export function Provider({ children }: Props) {
  const notifications = useStore($notifications)

  return (
    <>
      {notifications.map((params) => (
        <Fragment key={params.id}>
          {params.variant === 'bigInfo' ? (
            <BigInfoToast {...params} />
          ) : (
            <DefaultToast {...params} />
          )}
        </Fragment>
      ))}

      <>{children}</>
    </>
  )
}
