import { useStore } from 'effector-react'
import { profileModel } from '~/entities/Profile'

import { useDrawer } from '~/shared/ui/Drawer'
import {
  TabsDrawer,
  PassportDetailsDrawer,
  DriverLicenseDrawer,
  DriverEmailDrawer,
} from './ui'

export function Profile() {
  const isProfileOpen = useStore(profileModel.$isProfileDrawerOpen)
  const isDriverEmailDrawerOpen = useStore(
    profileModel.$isDriverEmailDrawerOpen,
  )
  const [
    isPassportDetailsDrawerShow,
    passportDetailsDrawerOpen,
    passportDetailsDrawerClose,
  ] = useDrawer()

  const [isDriverLicenseShow, driverLicenseOpen, driverLicenseClose] =
    useDrawer()

  return (
    <>
      <TabsDrawer
        isShow={isProfileOpen}
        onClose={profileModel.closeProfileDrawer}
        onEmailClick={profileModel.openDriverEmailDrawer}
        onPassportDetailsClick={passportDetailsDrawerOpen}
        onDriverLicenseClick={driverLicenseOpen}
      />

      <DriverEmailDrawer
        isShow={isDriverEmailDrawerOpen}
        onClose={profileModel.closeDriverEmailDrawer}
      />

      <PassportDetailsDrawer
        isShow={isPassportDetailsDrawerShow}
        onClose={passportDetailsDrawerClose}
      />

      <DriverLicenseDrawer
        isShow={isDriverLicenseShow}
        onClose={driverLicenseClose}
      />
    </>
  )
}
