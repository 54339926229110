import * as z from 'zod'

import { Taxipark } from '~/shared/api/taxipark'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel, ToManyRelation } from './core'

const attributeSchema = z.object({
  lastName: z.string(),
  firstName: z.string(),
  middleName: z.string(),
  email: z
    .string()
    .trim()
    .min(1, 'Обязательное поле')
    .email('Неверный адрес электронной почты'),
  birthDate: z.string(),
  phone: z.string(),
  passportIssueDate: z.string(),
  passportNumber: z.string(),
  passportIssued: z.string(),
  citizenship: z.string(),
  registrationAddress: z.string(),
  drivingExperienceStartDate: z.string(),
  drivingLicenseIssuingCountry: z.string(),
  drivingLicenseNumber: z.string(),
  drivingLicenseIssueDate: z.string(),
  drivingLicenseExpirationDate: z.string(),
  extId: z.string(),
  mainTaxiparkId: z.string(),
  emailVerifiedAt: dateSchema.nullable().optional(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type DriverAttributes = z.infer<typeof attributeSchema>

export class Driver extends ApiModel<typeof schema, DriverAttributes> {
  static jsonApiType = 'drivers'

  static schema = schema

  getFullName() {
    return [
      this.getAttribute('lastName'),
      this.getAttribute('firstName'),
      this.getAttribute('middleName'),
    ].join(' ')
  }
  getPassportNumber() {
    return this.getAttribute('passportNumber')
  }
  getExitId() {
    return this.getAttribute('extId')
  }
  getPassportIssued() {
    return this.getAttribute('passportIssued')
  }
  getPassportIssueDate() {
    return this.getAttribute('passportIssueDate')
  }
  getPhone() {
    return this.getAttribute('phone')
  }
  getEmail() {
    return this.getAttribute('email')
  }
  getEmailVerifiedAt() {
    return this.getAttribute('emailVerifiedAt')
  }
  getBirthDate() {
    return this.getAttribute('birthDate')
  }
  getRegistrationAddress() {
    return this.getAttribute('registrationAddress')
  }
  getCitizenship() {
    return this.getAttribute('citizenship')
  }
  getDrivingLicenseNumber() {
    return this.getAttribute('drivingLicenseNumber')
  }
  getDrivingLicenseIssueDate() {
    return this.getAttribute('drivingLicenseIssueDate')
  }
  getDrivingLicenseExpirationDate() {
    return this.getAttribute('drivingLicenseExpirationDate')
  }
  getDrivingLicenseIssuingCountry() {
    return this.getAttribute('drivingLicenseIssuingCountry')
  }

  getMainTaxiparkId(): UniqueId {
    return this.getAttribute('mainTaxiparkId')
  }
  taxiparks(): ToManyRelation<Taxipark, this> {
    return this.hasMany(Taxipark)
  }
  getTaxiparks(): Taxipark[] {
    return this.getRelation('taxiparks')
  }

  static async me() {
    const response = await Driver.with('taxiparks').find('me')
    return response?.getData()
  }

  static async setMainTaxipark(taxiparkId: UniqueId) {
    const url = `${Driver.getJsonApiUrl()}/set-main-taxipark`
    const client = Driver.httpClient.getImplementingClient()
    return await client.post(url, { taxiparkId })
  }

  static async updateEmail(email: string) {
    const url = `${Driver.getJsonApiUrl()}/update-email`
    const client = Driver.httpClient.getImplementingClient()
    return await client.put(url, { email })
  }

  static async resendVerificationEmailCode() {
    const url = `${Driver.getJsonApiUrl()}/resend-verification-email-code`
    const client = Driver.httpClient.getImplementingClient()
    return await client.post(url)
  }

  static async verifyEmail(code: string) {
    const url = `${Driver.getJsonApiUrl()}/verify-email`
    const client = Driver.httpClient.getImplementingClient()
    return await client.post(url, { code })
  }
}
