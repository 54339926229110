import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { driverModel } from '~/entities/Driver'
import { AxiosErrorType, Driver } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { notificationModalOpen } from '~/shared/lib/notificationModal'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { profileModel } from '../Profile'

const domain = createDomain('entities.driverEmail')
export const Gate = createGate()

// Check EmailVerifiedAt
sample({
  clock: Gate.open,
  source: driverModel.$driver,
  filter: (driver) => !driver?.getEmailVerifiedAt() && !!driver?.getEmail(),
  fn: () => {
    return {
      title: 'Вам необходимо подтвердить почту',
      message:
        'Для подтверждения перейдите по ссылке из письма, отправленного на Email, указанный при регистрации.',
      actions: profileModel.openDriverEmailDrawer,
      variant: 'bigInfo' as const,
    }
  },
  target: snackbarEnqueued,
})

// UpdateEmail
export type UpdateEmailParams = { email: string; successFn: () => void }

export const updateEmail = domain.createEvent<UpdateEmailParams>()
export const updateEmailFx = domain.createEffect<
  UpdateEmailParams,
  void,
  AxiosErrorType
>({
  async handler({ email, successFn }) {
    await Driver.updateEmail(email)
    successFn()
  },
})

sample({
  clock: updateEmail,
  target: updateEmailFx,
})

sample({
  clock: updateEmailFx.doneData,
  target: driverModel.getDriver,
})

sample({
  clock: updateEmailFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

// ResendVerificationEmailCode
export type ResendVerificationEmailCodeParams = { successFn: () => void }

export const resendVerificationEmailCode =
  domain.createEvent<ResendVerificationEmailCodeParams>()
export const resendVerificationEmailCodeFx = domain.createEffect<
  ResendVerificationEmailCodeParams,
  void,
  AxiosErrorType
>({
  async handler({ successFn }) {
    await Driver.resendVerificationEmailCode()
    successFn()
  },
})

sample({
  clock: resendVerificationEmailCode,
  target: resendVerificationEmailCodeFx,
})

sample({
  clock: resendVerificationEmailCodeFx.doneData,
  source: driverModel.$driver,
  fn(driver) {
    return {
      message: `Код подтверждения отправлен на почту ${
        driver?.getEmail() || '-'
      }`,
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: resendVerificationEmailCodeFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

// VerifyEmail
export type VerifyEmailParams = { code: string; successFn: () => void }

export const verifyEmail = domain.createEvent<VerifyEmailParams>()
export const verifyEmailFx = domain.createEffect<
  VerifyEmailParams,
  void,
  AxiosErrorType
>({
  async handler({ code, successFn }) {
    await Driver.verifyEmail(code)
    successFn()
  },
})

sample({
  clock: verifyEmail,
  target: verifyEmailFx,
})

sample({
  clock: verifyEmailFx.doneData,
  fn() {
    return {
      title: 'Ваша почта подтверждена',
      description: 'Данная электронная почта будет привязана к вашему аккаунту',
      variant: 'success' as const,
    }
  },
  target: [notificationModalOpen, driverModel.getDriver],
})

sample({
  clock: verifyEmailFx.failData,
  fn(e) {
    return {
      title: 'Не удалось подтвердить почту',
      description: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: notificationModalOpen,
})
