import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import { formSchema, FormValues } from './model'

type FormProps = {
  defaultValues: Partial<FormValues>
  onSuccess: (formValues: FormValues) => void
  isSubmitting: boolean
}

export function Form({ defaultValues, onSuccess, isSubmitting }: FormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const {
    formState: { isDirty },
  } = form

  return (
    <FormContainer formContext={form} onSuccess={onSuccess}>
      <TextFieldElement
        label='Email'
        name='email'
        fullWidth
        focused
        disabled={isSubmitting}
      />

      <Typography
        fontSize={14}
        lineHeight='18px'
        color='mutedText.main'
        mb='24px'
        mt='16px'
      >
        На новый Email будет отправлено письмо со ссылкой для подтверждения
      </Typography>

      <LoadingButton
        loading={isSubmitting}
        fullWidth
        type='submit'
        variant='limeFlooded'
        size='large'
        disabled={!isDirty}
      >
        Сохранить
      </LoadingButton>
    </FormContainer>
  )
}
