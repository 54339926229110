import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { useStoreMap } from 'effector-react'
import { useState } from 'react'
import { driverModel } from '~/entities/Driver'
import { Wrapper } from './styled'

type ConfirmationRequiredProps = {
  onCodeRepeat: () => void | Promise<void>
}

export function ConfirmationRequired({
  onCodeRepeat,
}: ConfirmationRequiredProps) {
  const [isLoading, toggleIsLoading] = useState<boolean>()

  const email = useStoreMap(driverModel.$driver, (driver) => driver?.getEmail())
  const emailVerifiedAt = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getEmailVerifiedAt(),
  )

  const handleCodeRepeat = async () => {
    try {
      toggleIsLoading(true)
      await onCodeRepeat()
    } finally {
      toggleIsLoading(false)
    }
  }

  if (!email || emailVerifiedAt) return null

  return (
    <Wrapper>
      <Typography fontSize={18} fontWeight={700} mb='8px'>
        Требуется подтверждение по Email
      </Typography>

      <Typography fontSize={16} mb='8px'>
        Для подтверждения перейдите по ссылке из письма, отправленного на Email,
        указанный при регистрации.
      </Typography>

      <LoadingButton
        sx={{ padding: 0 }}
        onClick={handleCodeRepeat}
        loading={isLoading}
      >
        Выслать письмо повторно
      </LoadingButton>
    </Wrapper>
  )
}
