import { DriverEmail } from '~/entities/DriverEmail'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { Title } from './styled'

type DriverEmailDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function DriverEmailDrawer({ isShow, onClose }: DriverEmailDrawerProps) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Title>Электронная почта</Title>

      <DriverEmail />
    </DrawerComponent>
  )
}
