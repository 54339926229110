import { z } from 'zod'
import { Driver } from '~/shared/api'

export const formSchema = Driver.schema.pick({
  email: true,
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = TypeFormSchema
