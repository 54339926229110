import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button } from '@mui/material'
import { useStoreMap } from 'effector-react'
import { driverModel } from '~/entities/Driver'
import { formatPhoneNumber } from '~/shared/lib/utils'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { BusinessUserIcon, CarSimpleIcon, PhoneIcon } from '~/shared/ui/icons'
import { Card, Title, TitleWrapper } from './styled'

type TabsDrawerProps = {
  isShow: boolean
  onClose: () => void
  onEmailClick: () => void
  onPassportDetailsClick: () => void
  onDriverLicenseClick: () => void
}

export function TabsDrawer({
  isShow,
  onClose,
  onEmailClick,
  onPassportDetailsClick,
  onDriverLicenseClick,
}: TabsDrawerProps) {
  const phone = useStoreMap(driverModel.$driver, (profile) =>
    profile?.getPhone(),
  )
  const email = useStoreMap(driverModel.$driver, (profile) =>
    profile?.getEmail(),
  )

  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Card sx={{ cursor: 'auto' }}>
        <TitleWrapper>
          <PhoneIcon />
          <Button href={`tel:+${phone}`} sx={{ m: 0, ml: '12px', p: 0 }}>
            <Title sx={{ ml: 0 }} data-testid='driver-phone'>
              {formatPhoneNumber(phone)}
            </Title>
          </Button>
        </TitleWrapper>
      </Card>

      <Card onClick={onEmailClick}>
        <TitleWrapper>
          <BusinessUserIcon />
          <Title data-testid='driver-email'>
            {email || 'Электронная почта'}
          </Title>
        </TitleWrapper>
        <ChevronRightIcon />
      </Card>
      <Card onClick={onPassportDetailsClick}>
        <TitleWrapper>
          <BusinessUserIcon />
          <Title>Паспортные данные</Title>
        </TitleWrapper>
        <ChevronRightIcon />
      </Card>
      <Card onClick={onDriverLicenseClick}>
        <TitleWrapper>
          <CarSimpleIcon />
          <Title>Водительские права</Title>
        </TitleWrapper>
        <ChevronRightIcon />
      </Card>
    </DrawerComponent>
  )
}
