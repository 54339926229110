import { styled, Box } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled(Box)`
  background: ${myPalette.white.main};
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(0, 21, 70, 0.1);
  padding: 16px;
  margin: 16px 0 16px;
`
