import { BigInfoNotification, snackbarClosed } from '../model'
import { Toast } from './styled'

type BigInfoToastProps = BigInfoNotification & { id: string }

export function BigInfoToast({
  id,
  title,
  message,
  actions,
}: BigInfoToastProps) {
  return (
    <Toast
      isOpen
      header={title}
      message={message}
      layout='stacked'
      position='top'
      swipeGesture='vertical'
      buttons={[
        {
          text: 'Подробнее',
          handler: () => actions?.(),
        },
        {
          text: 'Скрыть',
          role: 'cancel',
        },
      ]}
      translucent
      onDidDismiss={() => snackbarClosed(id)}
    />
  )
}
